import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import Cta from "../components/cta"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"

const ChatbotPage = () => (
    <Layout>
        <Seo  
            title="Chatbot developers" 
            description="Launch Lab develops chatbots for the web, Messenger, SMS and email. We are web application developers in Australia."
            pathname="/chatbot-developers/"
        />

        <section role="main" className="bg-purple with-pad-sm white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="100"
                      data-sal-duration="400"
                      >Chatbot Developers Sydney and Canberra</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="200"
                      data-sal-duration="400"
                      >
                          We develop chatbots using AWS Lex and Google's Dialogflow
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>The bots, chatbots &amp; no UI ideas we're interested in</h3>
                        </div>

                        <div className="col-67 long-text">
                          <p>
                            Launch Lab offers chatbot development in Sydney, Canberra and throughout Australia to businesses, Government and startups.
                          </p>
                          <p>
                            If you are looking for chatbots developers or voice app developers or you simply want to discuss whether your next application should have a user interface or should be a conversational bot, we'd love to chat.
                          </p>
                          <p>You can read about the types of chatbots we're interested in working on below.</p>
                          <div className="btn-row">
                              <Link to="/contact/" className="btn btn-lg btn-purple">
                                  <span className="flex flex-ac">
                                      Contact us
                                  <ArrowRight />
                                  </span>
                              </Link>
                          </div>

                          <div className="mt-4">
                            <h4>Twitter bots</h4>
                            <p>Some Twitter bots can be useful! Like this Twitter bot we developed <a href="https://twitter.com/AusPolDonations">@AusPolDonations</a>. The bot helps people find political donation data with a simple tweet.</p>

                            <StaticImage 
                                src="../images/auspol.png"
                                alt="A tweet showing the Twitter bot Launch Lab developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="constrained"
                                quality="100%"
                                idth= "600px"
                                className="with-radius"
                            />

                            <h4>Web based bots</h4>
                            <p>Does your startup need a fully designed user interface with dashboards? Or, could your homepage give your users access to a chatbot they can interact with to use your product? Maybe you work in marketing for a more established company and are considering building a chatbot for your next online competition, or you want to add a self-help chatbot to your site to improve UX.</p>

                            <h4>Skills / Actions</h4>
                            <p>With many hundred million units sold, smart speakers like the Amazon Echo and Google Home are becoming staples in the home. Could your users chat with Alexa to use your product? Apps on the Echo are called Skills. Voice apps for Google Assistant are called Actions. If you need help developing a Skill for the Amazon Echo, or an Action for Google Home we'd love to discuss your idea further.</p>

                            <h4>Email bots</h4>
                            <p>Similar to SMS bots, email bots offer you the opportunity to develop a product that is 'backend only' and leverages off an interface that people have trusted for decades and use daily.</p>

                            <h4>Messenger bot</h4>
                            <p>This is where no UI comes to the fore. Do you really need your own user interface or could you build a Messenger bot instead and leverage their UI? A UI that billions of people are familiar with.</p>

                            <h4>SMS bots</h4>
                            <p>Another great example of an application with no user interface. Could you offer a service where users use the product not via a mobile app or a website, but via a user interface they already know and have used thousands of times ... SMS.</p>

                            <h4>Slack bots</h4>
                            <p>There are thousands of Slack bots to help you be more productive on Slack. Plus, as a startup founder or provider of business services, building a Slack Bot gives you an instant distribution channel to millions of users.</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a chatbot and / or a voice app development quote from an Australian web development team"
        />
    </Layout>
)

export default ChatbotPage